<template>
	<div class="fund-pie-chart">
		<h3 class="text-center">{{fundName}}</h3>
		<div class="pie-box mt-5">
			<Pie :data="data" :options="options" :width="124" :height="124"/>
		</div>
		
		<ul class="list-unstyled list-inline text-center">
			<li class="invested-amt">Investment: {{amtInvestedDollars}}</li>
			<li class="fees-amt">Fees: {{amtFeesDollars}}</li>
			<li class="expenses-amt">Expenses: {{amtExpensesDollars}}</li>
			<li class="available-amt">Available: {{amtAvailableDollars}}</li>
		</ul>
	</div>
</template>

<script type="text/javascript">
	import { Pie } from 'vue-chartjs'
	import {formatDollars} from '@/utils/currency_utils'
	import {
		Chart as ChartJS,
		Title,
		Tooltip,
		Legend,
		ArcElement,
		CategoryScale,
	} from 'chart.js'

	ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

	const INVESTED_COLOR = '#005cb9';
	const FEES_COLOR = '#1f1a4d';
	const EXPENSES_COLOR = '#6e9dcd';
	const AVAIL_COLOR = '#05a662';

	export default {
		name: 'FundChart',
		inheritAttrs: false,

		mixins: [],

		components: {
			Pie
		},

		filters: {
		},

		provide: function() {
			return {
			}
		},

		inject: [],

		props: {
			fundName: {
				type: String,
				required: true
			},
			amtInvested: {
				type: Number,
				required: true
			},
			amtFees: {
				type: Number,
				required: true
			},
			amtExpenses: {
				type: Number,
				required: true
			},
			amtAvailable: {
				type: Number,
				required: true
			}
		},

		data() {
			return {
			
			}
		},

		computed: {
			amtInvestedDollars() {
				return `${formatDollars(this.amtInvested)}`
			},

			amtFeesDollars() {
				return `${formatDollars(this.amtFees)}`
			},

			amtExpensesDollars() {
				return `${formatDollars(this.amtExpenses)}`
			},

			amtAvailableDollars() {
				return `${formatDollars(this.amtAvailable)}`
			},

			data() {
				return {
					labels: ['Available', 'Fees', 'Expenses', 'Invested'],
					datasets: [
						{
							backgroundColor: [AVAIL_COLOR, FEES_COLOR, EXPENSES_COLOR, INVESTED_COLOR],
							data: [this.amtAvailable, this.amtFees, this.amtExpenses, this.amtInvested],
							borderWidth: 0
						}
					]
				}
			},

			options() {
				return {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						}
					}
				}
			}
		},

		watch: {

		},

		created() {
		},

		mounted() {
		},

		methods: {
		}
	}
</script>

<style lang="scss" scoped>
	@import '@/styles/custom-variables';

	.invested-amt {
		color: $darkblue;
	}

	.fees-amt {
		color: $navy;
	}

	.expenses-amt {
		color: $midblue;
	}

	.available-amt {
		color: $green;
	}
	.pie-box {
	}
</style>
