<template>
	<div class="row gx-5">
		<div class="col-12">
			<div>
				<h3>ESG Scorecard <span class="badge badge-primary">beta</span></h3>
				<div class="d-flex justify-content-left mb-3" v-if="dataLoaded">
					<p class="as-of-date" v-if="isAsOfADate">As Of: <format-date :date="asOf" :utc-time="true" :to-format="'MM/dd/yy'"></format-date></p>
					<p class="as-of-date" v-else-if="asOf != null">As Of: {{ asOf }}</p>
				</div>
			</div>
		</div>
		<div class="col-12" v-if="dataLoaded">
			<table class="table tbody-striped">
				<colgroup span="2"></colgroup>
				<colgroup span="3"></colgroup>
				<colgroup span="3"></colgroup>
				<thead>
				<tr class="no-bottom-border">
					<th colspan="2" scope="colgroup"></th>
					<th class="text-center major-heading col-border-left col-border-right" colspan="3" scope="colgroup">Initial
						Investment
					</th>
					<th class="text-center major-heading " colspan="3" scope="colgroup">Current</th>
				</tr>
				</thead>
				<thead>
				<tr>
					<th>Dimension</th>
					<th>Criteria</th>
					<th class="col-border-left">ESG&nbsp;Score</th>
					<th>Status</th>
					<th class="col-border-right">Opportunities</th>
					<th>ESG&nbsp;Score</th>
					<th>Status</th>
					<th>Opportunities</th>
				</tr>
				</thead>
				<tbody v-for="entry in cardEntries" :key="entry.key">
				<tr class="no-bottom-border" v-for="subEntry in entry.subEntries" :key="subEntry.key">
					<td v-if="subEntry.key == 0" :rowspan="entry.subEntries.length" scope="rowgroup">{{ entry.dimension }}</td>
					<td class="multiline">{{ displayCriteria(subEntry.criteria) }}</td>
					<td><img v-if="subEntry.initial.score" class="score-img" :src="getScoreSvg(subEntry.initial.score)" :alt="subEntry.initial.score"></td>
					<td class="multiline">{{ subEntry.initial.status }}</td>
					<td class="multiline">{{ subEntry.initial.opportunities }}</td>
					<td><img v-if="subEntry.current.score" class="score-img" :src="getScoreSvg(subEntry.current.score)" :alt="subEntry.current.score"></td>
					<td class="multiline">{{ subEntry.current.status }}</td>
					<td class="multiline">{{ subEntry.current.opportunities }}</td>
				</tr>
				</tbody>
			</table>
		</div>
		<div class="col-12" v-else>
			<table class="table tbody-striped">
				<colgroup span="2"></colgroup>
				<colgroup span="3"></colgroup>
				<colgroup span="3"></colgroup>
				<thead>
				<tr class="no-bottom-border">
					<th colspan="2" scope="colgroup"></th>
					<th class="text-center major-heading col-border-left col-border-right" colspan="3" scope="colgroup">Initial
						Investment
					</th>
					<th class="text-center major-heading " colspan="3" scope="colgroup">Current</th>
				</tr>
				</thead>
				<thead>
				<tr>
					<th>Dimension</th>
					<th>Criteria</th>
					<th class="col-border-left">ESG&nbsp;Score</th>
					<th>Status</th>
					<th class="col-border-right">Opportunities</th>
					<th>ESG&nbsp;Score</th>
					<th>Status</th>
					<th>Opportunities</th>
				</tr>
				</thead>
				<tbody v-for="(item, index) in emptyData" :key="index">
					<tr class="no-bottom-border">
						<td>{{ item.category }}</td>
						<td v-html="item.details"></td>
						<td v-for="i in 6" :key="i"></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>


<script type="text/javascript">
import {DateTime} from "luxon";
import FormatDate from "@/views/utils/FormatDate.vue";

export default {
	inheritAttrs: false,

	mixins: [],

	components: {FormatDate},

	filters: {},

	provide: {},

	inject: [],

	props: {
		sourceData: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			data: this.sourceData,
			emptyData: [
				{ category: 'Environment', details: 'Energy consumed <br> Renewable opportunities <br> Air quality / GHG emissions' },
				{ category: 'Human Capital', details: 'Employee safety <br> Total recordable incident rate (TRIR)' },
				{ category: 'Business Model & Innovation', details: 'Sourcing of any critical raw materials' },
				{ category: 'Business Model & Innovation', details: 'Remanufactured products or remanufacturing services' },
				{ category: 'Leadership & Governance', details: 'Transportation accident and safety management' }							
			]			
		}
	},

	computed: {
		dataLoaded() {
			return this.data != null && Object.keys(this.data).length > 0 && this.data.data != null
		},
		isAsOfADate() {
			if (this.dataLoaded) {
				try {
					return this.data.data.asOf ? DateTime.fromISO(this.data.data.asOf).isValid : false
				} catch(e) {
					return false
				}
			}
			return true
		},
		asOf() {
			if (this.dataLoaded) {
				return this.data.data.asOf
			}
			return null
		},
		cardEntries() {
			const entries = []
			const source = this.data.data.entries
			if (this.dataLoaded) {
				for (let i = 0; i < source.length; i++) {
					const subEntries = []
					for (let j = 0; j < source[i].subEntries.length; j++) {
						subEntries.push(source[i].subEntries[j])
						subEntries[j].key = j
					}
					entries.push(source[i])
					entries[i].key = i
					entries[i].subEntries = subEntries
				}
			}
			return entries
		},
	},

	watch: {
		sourceData: function (value) {
			this.data = value
		}
	},

	methods: {
		getScoreSvg(score) {
			return require(`@/assets/esg_score/${score}.svg`)
		},

		displayCriteria(criteria) {
			return criteria.join('\n')
		}
	}
}

</script>


<style lang="scss">
@import '../../../styles/custom-variables.scss';

.multiline {
	white-space: pre-wrap;
}

.tbody-striped > tbody:nth-of-type(2n) {
	background-color: $zebra;
}

.major-heading {
	background-color: rgba($chartblue, 0.13) !important;
	color: $navy;
	font-size: 15px;
}

.no-bottom-border > th, .no-bottom-border > td {
	border-bottom: none;
}

.col-border-right {
	border-right: 13px solid white !important;
}

.col-border-left {
	border-left: 13px solid white !important;
}

.score-img {
	width: 100%;
	position: relative;
	border-radius: 50%;
	height: 22px;
}
</style>
