<template>
	<div>
		<div class="row">
			<div class="col">
				<bread-crumb
						:breadcrumbData="breadcrumbData" />
			</div>
		</div>
		<div class="row border-bottom my-4" v-if="!loadingData">
			<div class="col-12">
				<div class="investment-heading d-flex flex-lg-row justify-content-between">
					<h1 v-if="hasCompany">{{companyName}}</h1>
					<ul class="list-inline">
						<li class="list-inline-item"><button class="btn btn-secondary" v-on:click="showRequestUpdateModal">Request Update</button></li>
						<li class="list-inline-item">
							<a v-if="hasLatestFinancials" class="btn btn-primary" v-on:click.stop v-on:click.prevent="downloadFile" v-bind:data-url="companyFinancialsUrl" v-bind:data-filename="companyFinancialsFileName">Export Report</a>
							<a v-else><a class="btn btn-primary" href="#" v-on:click.prevent="downloadTemplate">Download Financials Template</a></a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-12 row" v-if="hasData">
				<div class="graphite col-6">{{ headingData.description }}</div>
				<div class="col-12 row mt-4 header-data border-bottom">
					<div class="col-3 d-flex flex-column">
						<h4 class="mb-1 d-flex flex-row align-items-center">
							<span>Industry:&nbsp;</span>
							<div class="industry-text card border-0">
								<span class="m-1">{{ headingData.industry }}</span>
							</div>
						</h4>
						<div>
							<h4>Fund: 
								<span v-for="(value, key, index) in formattedFundList" :key="key">
									<span class="fund-title">{{ key }}</span> 
									<span>
										<format-percent :number="value" /> 
										<span class="px-1" v-if="index != Object.keys(formattedFundList).length - 1">| </span> 
									</span>
								</span>
							</h4>
							
						</div>
					</div>
					<div class="mt-1 col-3 d-flex flex-column justify-content-center">
						<h4 class="mb-2">Initial Investment:
							<format-date :date="headingData.investment" :utc-time="true"/>
						</h4>
						<h4>Investment Status:
							<span>{{ headingData.status }}</span>
						</h4>
					</div>
					<div class="mt-1 col-4 d-flex flex-column justify-content-center">
						<h4 class="mb-2">Ownership at Entry:
							<format-percent :number="headingData.ownership"/>
						</h4>
						<h4>Lead/Co-Lead:
							<span>{{ headingData.lead }}</span>
						</h4>
					</div>
					<div class="mt-1 col-2 d-flex flex-column justify-content-center align-items-end">
						<h4 class="mb-2 overflow">HQ:
							<span>{{ headingData.hq }}</span>
						</h4>
						<h4>Last Update:
							<format-date :date="headingData.update"/>
						</h4>
					</div>
				</div>
				<div class="row mt-3 header-data ">
					<div class="col-6 d-flex flex-column">
						<h4 class="mb-2">Data Update Owner(s):
							<span>{{ headingData.updateOwners }}</span>
						</h4>
						<h4>Auto Data Request:
							<span>n/a</span>
						</h4>
					</div>
					<div class="col-6 d-flex flex-column">
						<div class="d-flex flex-row justify-content-between">
							<h4 class="mb-2">Validation Owner(s):
								<span>{{ headingData.validateOwners }}</span>
							</h4>
							<a href="#" v-on:click.prevent="onEditReporting">Edit Data Reporting</a>
						</div>
						<h4>Last Data Upload:
							<format-date :date="headingData.update"/>
						</h4>
					</div>
				</div>
			</div>
			<div v-if="!hasData">
				<!-- NO DATA TODO -->
			</div>
			
		</div>
		<div class="row border-bottom my-4" v-else>
			<div class="d-flex justify-content-between my-1 placeholder-wave mb-4" style="height: 50px;">
				<span class="placeholder col-4"></span>
				<span class="placeholder col-3"></span>
			</div>
			<div class="d-flex justify-content-between my-1 placeholder-wave mb-4" style="height: 30px;">
				<span class="placeholder col-6"></span>
			</div>
			<div class="d-flex justify-content-between my-1 placeholder-wave pb-3 border-bottom" style="height: 60px;">
				<span class="placeholder col-12"></span>
			</div>
			<div class="d-flex justify-content-between my-1 placeholder-wave mb-3" style="height: 60px;">
				<span class="placeholder col-12"></span>
			</div>

		</div>
		<div class="row" :class="{'d-none': (loadingData || !hasData)}">
			<div class="col-12">
				<ul id="companyInvestmentTabNav" class="list-inline" role="tablist">
					<li class="list-inline-item mb-2" role="presentation">
						<button class="btn pill active" id="companyOverviewData" data-bs-toggle="tab" data-bs-target="#companyOverview">Company Overview</button>
					</li>
					<li class="list-inline-item mb-2" role="presentation">
						<button class="btn pill" id="coinvestLenderData" data-bs-toggle="tab" data-bs-target="#coinvestLender">Co-invest &amp; Lender Data</button>
					</li>
					<li class="list-inline-item mb-2" role="presentation">
						<button class="btn pill" id="financialsActualsData" data-bs-toggle="tab" data-bs-target="#financialsActuals">Financials/Actuals</button>
					</li>
					<li class="list-inline-item mb-2" role="presentation">
						<button class="btn pill" id="addOnAcquisitionsData" data-bs-toggle="tab" data-bs-target="#addOnAcquisitions">Add-on Acquisitions</button>
					</li>
					<li class="list-inline-item mb-2" role="presentation">
						<button class="btn pill" id="esgFormData" data-bs-toggle="tab" data-bs-target="#esgForm">ESG Data</button>
					</li>
					<li class="list-inline-item mb-2" role="presentation">
						<button class="btn pill" id="esgScorecardData" data-bs-toggle="tab" data-bs-target="#esgScorecard">ESG Scorecard</button>
					</li>
				</ul>
				<div class="tab-content mt-4">
					<div class="tab-pane active" id="companyOverview" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
						<company-overview
								:source-data="companyOverviewData" />
					</div>
					<div class="tab-pane" id="coinvestLender" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
						<co-invest-lenders
							:source-data="coInvestData"
							:lenders="lendersData"/>
					</div>
					<div class="tab-pane" id="financialsActuals" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
						<financials-actuals
								:source-data="financialsActualsData" />
					</div>
					<div class="tab-pane" id="addOnAcquisitions" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
						<add-on-acquisitions
								:source-data="addOnAcquisitionsData"/>
					</div>
					<div class="tab-pane" id="esgForm" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
						<esg-form
								:source-data="esgFormData"/>
					</div>
					<div class="tab-pane" id="esgScorecard" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
						<esg-scorecard
								:source-data="esgScorecardData"/>
					</div>
				</div>
			</div>
		</div>
		<div class="row" :class="{'d-none': hasData, 'd-block': !hasData}">
			<div class="col" v-if="!loadingData">
				<div class="alert alert-secondary">
					<p>No data available.</p>
				</div>
			</div>
		</div>
		<div class="row" v-if="loadingData">
			<div class="d-flex justify-content-between my-2 placeholder-wave" style="height: 40px;">
				<span class="placeholder col-12"></span>
			</div>
			<div class="d-flex justify-content-between my-2 placeholder-wave" style="height: 150px;">
				<span class="placeholder col-12"></span>
			</div>
		</div>
		<div class="row" v-if="loadingData">
			<div class="col d-flex flex-column placeholder-wave">
				<span class="placeholder my-2" style="height: 630px;"></span>
				<span class="placeholder my-2" style="height: 270px;"></span>
				<span class="placeholder my-2" style="height: 150px;"></span>
			</div>
			<div class="col d-flex flex-column placeholder-wave">
				<span class="placeholder my-2" style="height: 500px;"></span>
				<span class="placeholder my-2" style="height: 200px;"></span>
				<span class="placeholder my-2" style="height: 170px;"></span>
				<span class="placeholder my-2" style="height: 200px;"></span>
			</div>
		</div>
	</div>
	<edit-associates-modal @close="closeEditAssociatesModal" ref="editAssociatesModal"/>
	<request-update-modal :investment-id="this.$route.params.investmentId" :visible="isRequestUpdateVisible" @close="closeRequestUpdateModal"></request-update-modal>
</template>

<script type="text/javascript">
	import BreadCrumb from '@/views/nav/BreadCrumb.vue'
	import AddOnAcquisitions from './companyInvestment/AddOnAcquisitions.vue'
	import FinancialsActuals from './companyInvestment/FinancialsActuals.vue'
	import CoInvestLenders from './companyInvestment/CoInvestLenders.vue'
	import CompanyOverview from './companyInvestment/CompanyOverview.vue'
	import EsgForm from './companyInvestment/EsgForm.vue'
	import EsgScorecard from './companyInvestment/EsgScorecard.vue'
	import RequestUpdateModal from './../shared/RequestUpdateModal'
	import FormatDate from './../utils/FormatDate.vue'
	import FormatPercent from '../utils/FormatPercent.vue'
	import EditAssociatesModal from './companyInvestment/EditAssociatesModal.vue'
	import {downloadFile} from "@/utils/file_utils";

	import {UploadsApi} from '@/api/uploads_api'
	import {getDownloadURL, getStorage, ref} from "firebase/storage";
	import StorageConstants from "@/utils/storage_constants";
	import FileDownload from "@/mixins/FileDownload";
	import {InvestmentsApi} from '@/api/investment_api'
	

export default {
	inheritAttrs: false,

	mixins: [FileDownload],

	components: {
		BreadCrumb,
		AddOnAcquisitions,
		FinancialsActuals,
		CoInvestLenders,
		CompanyOverview,
		RequestUpdateModal,
		EditAssociatesModal,
		FormatDate,
		FormatPercent,
		EsgForm,
		EsgScorecard
	},

	filters: {
	},

	provide: function() {
		return {
		}
	},

	inject: [],

	props: {
	},

	data() {
		return {
			industry: '',
			formattedFundList: '',
			initialInvestmentDate: null,
			investmentStatus: null,
			ownershipPercentAtEntry: null,
			formattedHeadquarters: null,
			companyName: '',
			addOnAcquisitionsData: [],
			documentsData: {},
			financialsActualsData: {},
			coInvestData: [],
			lendersData: [],
			companyOverviewData: {},
			esgFormData: {},
			esgScorecardData: {},
			brandingLocationData: {},
			companyFinancialsUrl: null,
			companyFinancialsFileName: null,
			latestUpload: null,
			isRequestUpdateVisible: false,
			usersData: {},
			loadingData: true
		}
	},

	computed: {
		hasData() {
			return this.companyOverviewData && (Object.keys(this.companyOverviewData).length > 0)
		},

		hasCompany() {
			return this.companyName != null && this.companyName != ''
		},

		hasLatestFinancials() {
			return this.companyFinancialsUrl != null
		},

		breadcrumbData() {
			return [
				{
					linkText: 'Investments',
					routerLinkName: 'InvestmentsView'
				},
				{
					linkText: this.companyName
				}
			]
		},
		headingData() {
			let data = {}
			console.log(this.companyOverviewData)
			if (this.hasData) {
				data.description = this.companyOverviewData['Company Attributes']['Company Description']
				data.industry = this.companyOverviewData['Company Attributes']['Industry']
				data.fund = this.companyOverviewData['Bertram Fund Allocation']['Allocated Funds']
				data.investment = this.companyOverviewData['Company Attributes']['Date of Initial Investment']
				data.status = this.companyOverviewData['Company Attributes']['Investment Status']
				data.ownership = this.companyOverviewData['Company Attributes']['Ownership at entry (%)']
				data.lead = this.companyOverviewData['Company Attributes']['Lead/Co-lead']
				data.hq = this.companyOverviewData['Company Attributes']['HQ city, state, country']
				if (this.latestUpload != null) {
					data.update = this.latestUpload.last_updated
				}
				if (this.usersData != {}) {
					data.updateOwners = this.formatUserList(this.usersData.investment_financials_uploader)
					data.validateOwners = this.formatUserList(this.usersData.investment_financials_verifier)
				}
			}
			return data
		}
	},

	watch: {

	},

	created() {
	},

	mounted() {
		this.setupTabNavListeners()
		this.get_companyOverviewData()
		return Promise.all([
			this.loadCompanyData(),
			this.loadLatestUpload(),
			this.loadInvestmentUsers()
		])
	},

	methods: {
		loadInvestmentUsers() {
			InvestmentsApi.getInvestmentUsers(this.$route.params.investmentId)
				.then(users => {
					console.log('users', users)
					this.usersData = users.data
				})
		},

		async loadLatestUpload() {
			const uploads = await UploadsApi.getUploadsForObject({objectId: this.$route.params.investmentId, limit: 1, status: 'COMPLETE'})
			if(uploads.data.totalRows > 0) {
				this.latestUpload = uploads.data.uploads[0]
				console.log("latest upload", this.latestUpload)
				this.companyFinancialsFileName = this.latestUpload.upload_data.fileName
				this.companyFinancialsUrl = this.latestUpload.upload_data.url
			}
		},

		loadCompanyData() {
			this.loadingData = true
			InvestmentsApi.getInvestment(this.$route.params.investmentId)
				.then(invResp => {
					console.log('invResp', invResp)
					this.companyName = invResp.data.name
					this.financialsActualsData = invResp.data.financials
					if(typeof(invResp.data.overview) != 'undefined') {
						this.companyOverviewData = invResp.data.overview.data
						this.formattedFundList = this.formatFundList(this.companyOverviewData["Bertram Fund Allocation"])
					}
					this.coInvestData = invResp.data.coinvest
					this.lendersData = invResp.data.lenders
					this.addOnAcquisitionsData = invResp.data.acquisitions
					this.esgFormData = invResp.data.esgForm
					this.esgScorecardData = invResp.data.esgScoreCard
				}).finally(() => {
					this.loadingData = false
				})
		},

		onEditReporting() {
			this.$refs.editAssociatesModal.show(this.$route.params.investmentId)
		},	

		closeEditAssociatesModal() {
			this.loadInvestmentUsers()
			this.$refs.editAssociatesModal.hide()
		},

		get_companyOverviewData() {
		},

		get_addOnAcquisitionsData() {
		},

		get_financialsActualsData() {
		},

		get_investmentDocumentsData() {
			console.log('TODO::get_investmentDocumentData')
		},

		get_coinvestLenderData() {
		},

		setupTabNavListeners() {
			[
				'#companyOverview',
				'#coinvestLender',
				'#financialsActuals',
				'#addOnAcquisitions'
			].forEach(targSel => {
				const sel = document.querySelector(`#companyInvestmentTabNav button[data-bs-target="${targSel}"`)
				sel.addEventListener('show.bs.tab', ev => {
					const func = this[`get_${ev.target.id}`]
					if(!func) {
						throw new Error(`Unknown data retrieval method.  New tab implemented? :: ${ev.target.id}`)
					}
					func()
				})
			})
		},
		async downloadTemplate() {
			if(this.latestUpload) {
				downloadFile({fileName: this.latestUpload.upload_data.fileName, url: this.latestUpload.upload_data.url, immediate: false}).download()
			} else {
				const storage = getStorage(),
							storeRef = ref(storage, StorageConstants.FINANCIALS_UPLOAD_TEMPLATE_PATH),
							dlUrl = await getDownloadURL(storeRef)
				
				downloadFile({fileName: StorageConstants.FINANCIALS_UPLOAD_TEMPLATE_FILENAME, url: dlUrl, immediate: false}).download()
			}
		},
		closeRequestUpdateModal() {
			this.isRequestUpdateVisible = false
		},
		showRequestUpdateModal() {
			this.isRequestUpdateVisible = true
		},
		formatUserList(list) {
			if (list != null) {
				return list.map(user => user.name).join(', ') ?? []
			} else {
				return 'Not set'
			}
		},
		formatFundList(fundList) {
			const displayFunds = {}
			for (let fund in fundList) {
				console.log('fund', fund, 'fund value', fundList[fund])
				if (fund != 'Annex' && typeof fundList[fund] === 'number' && fundList[fund] > 0){
					displayFunds[fund] = fundList[fund]
				}
			}
			return displayFunds
		}
	}
}
</script>

<style lang="scss">
@import '../../styles/custom-variables.scss';
h1 {
	color: $navy;
}
.graphite {
	color: $graphite !important;
}
.investment-heading {
	height: 39px;
}
.header-data {
	div {
		h4 {
			color: $graphite;
		}
	}
}
.industry-text {
	background-color: rgba(191, 215, 239, 0.40) !important;
}
.fund {
	font-size: 16px;
}
.fund-title {
	font-weight: 900;
	padding-right: .4em;
	text-shadow: .05px 0 currentColor, -.05px 0 currentColor, 0 .05px currentColor, 0 -.05px currentColor;
}
.overflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
