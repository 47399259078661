<template>
	<div class="row gx-5">
		<div class="col-42">
			<div>
				<div>
					<h3>ESG Information <span class="badge badge-primary">beta</span></h3>
					<div class="d-flex justify-content-left mb-3">
						<p class="as-of-date">As Of: <format-date :date="esgFormValue('ESG Data as of:')" :from-format="'MM/dd/yy'" :utc-time="true"/></p>
					</div>
				</div>
				<dl class="row dl-rev-striped company-attributes ms-0" v-if="dataLoaded">
					<template v-for:="(value, key) in filteredEsgInfo">
						<dt class="col-8"><span>{{ key }}</span></dt>
						<dd v-if="isPercent(key)" class="col-4"><span><format-percent :number="value"/></span></dd>
						<dd v-else class="col-4"><span></span>{{ value }}</dd>
					</template>
				</dl>
				<dl class="row dl-rev-striped company-attributes ms-0" v-else>
					<template v-for:="value in emptyData">
						<dt class="col-8"><span>{{ value }}</span></dt>
						<dd class="col-4"><span></span></dd>
					</template>
				</dl>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
import FormatDate from '@/views/utils/FormatDate'
import FormatPercent from '@/views/utils/FormatPercent'

export default {
	inheritAttrs: false,

	mixins: [],

	components: {
		FormatDate,
		FormatPercent
	},

	filters: {},

	provide: {},

	inject: [],

	props: {
		sourceData: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			data: this.sourceData,
			emptyData: ["Scope 1 Emissions (mtCO2e)", "Scope 1 predominant Methodology", "Scope 2 Emissions (mtCO2e)", "Scope 2 predominant Methodology",
				"Scope 3 Emissions (mtCO2e) (optional)", "Scope 3 Methodology", "Total energy consumption (kWh)", "Renewable energy consumption (kWh)",
				"Total number of board members", "Number of women board members", "Number of LGBTQ board members (optional)", "Number of board members from under-represented groups (optional)",
				"Total number of C-Suite employees (optional)", "Number of women C-suite employees (optional)", "Number of work-related injuries", "Number of work-related fatalities", "Days lost due to injury (days)", 
				"Total number of Full Time Equivalents (FTE) in current year (column 'Year')", "Total number of Full Time Equivalents (FTE) in previous year (column 'Year' - 1)", "Net change in FTEs due to M&A",
				"Organic Net New Hires (Current Year FTEs - Previous Year FTEs - (net change in FTEs due to M&A))", "Total Net New Hires (Current Year FTEs - Previous Year FTEs)", "Annual Percent Turnover (%) for FTEs",
				"Do you conduct an employee survey regularly (Y/N)?", "% employees responding to survey (optional)"
			]			
		}
	},

	computed: {
		dataLoaded() {
			return this.data != null && Object.keys(this.data).length > 0 && this.data.data != null
		},
		esgInfo() {
			if (this.dataLoaded) {
				return this.data.data
			}
			return null
		},
		filteredEsgInfo() {
			const info = this.esgInfo
			if (info != null) {
				const filtered = {}
				// First, sort the data by the displayOrder
				info.sort((a, b) => a.displayOrder - b.displayOrder)
				// Second, flatten it all out
				info.forEach( function (row) {
					if (row.label !== 'ESG Data as of:') {
						filtered[row.label] = row.value
					}
				});
				return filtered
			}
			return null

		}
	},

	methods: {
		esgFormValue(key) {
			const info = this.esgInfo
			if (info != null) {
				// Find the row that has an object with the label set to key
				for (const row of info) {
					if (row.label === key) {
						return row.value
					}
				}
			}
			return null
		},
		isPercent(key) {
			return key.includes('%') || key.toLowerCase().includes('percent')
		}
	},
	watch: {
		sourceData: function (value) {
			this.data = value
		}
	}
}
</script>

<style lang="scss">
</style>
